import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  Slider,
  Typography,
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { SmartToy } from "@mui/icons-material";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

const MAX_POINTS_PER_PART = 10;

const translations = {
  title: "Distribuição de Pontos por Traço de Caráter",
  bodyPart: "Parte do Corpo",
  fieldOfWork: "Área de Atuação",
  name: "Nome",
  schizoid: "Esquizoide",
  oral: "Oral",
  masochist: "Masoquista",
  psychopath: "Psicopata",
  rigid: "Rígido",
  head: "Cabeça",
  eyes: "Olhos",
  mouth: "Boca",
  torso: "Tronco",
  hips: "Quadril",
  legs: "Pernas",
  totalPointsByTrait: "Total de Pontos por Traço",
  traitDistribution: "Distribuição Percentual dos Traços",
  remainingPoints: "Pontos Restantes",
  exportData: "Exportar Dados",
  importData: "Importar Dados",
  reset: "Reiniciar",
  insights: "Gerar Relatório",
  exportPDF: "Exportar PDF",
  loadingInsights: "Gerando Relatório...",
  importError: "Erro ao importar dados. Verifique o arquivo JSON.",
  fileNames: {
    json: "profile.json",
    pdf: "report.pdf",
  },
  insightsSections: {
    mainQualities: "PRINCIPAIS QUALIDADES E CAPACIDADES",
    emotionalChallenges: "ASPECTOS EMOCIONAIS E DESAFIOS PESSOAIS",
    conclusion: "CONCLUSÃO",
    strategic_vision_and_creativity: "Visão Estratégica e Criativa",
    focus_on_excellence_and_resilience: "Foco na Excelência e Resiliência",
    empathy_and_connection: "Empatia e Habilidade de Conexão",
    influence_and_negotiation_skills: "Capacidade de Influência e Negociação",
  },
  prompts: {
    generateInsights: (name, fieldOfWork, distributionText) => `
        Geração de relatório para ${name}, área de atuação: ${fieldOfWork}.
        Baseado na seguinte distribuição percentual dos traços de caráter: ${distributionText}.

        Estrutura desejada em JSON com formatação Markdown:
        {
          "main_qualities_and_capabilities": {
            "strategic_vision_and_creativity": "Escreva um parágrafo sobre a visão estratégica e criatividade do indivíduo. Foque em como esses aspectos influenciam positivamente seu desempenho.",
            "focus_on_excellence_and_resilience": "Descreva como a busca pela excelência e a resiliência são características marcantes na atuação profissional do indivíduo.",
            "empathy_and_connection": "Explique a habilidade do indivíduo de se conectar com os outros, destacando sua empatia em interações pessoais e profissionais.",
            "influence_and_negotiation_skills": "Aborde a capacidade do indivíduo de influenciar e negociar de forma eficaz, realçando como ele utiliza essas habilidades em sua área."
          },
          "emotional_aspects_and_personal_challenges": "Descreva os desafios emocionais e pessoais mais relevantes para o indivíduo, com foco nos impactos sobre a sua atuação profissional e como esses desafios podem ser superados.",
          "conclusion": "Apresente uma conclusão concisa destacando como o conjunto de qualidades e desafios do indivíduo impactam sua trajetória profissional, com uma visão geral sobre seu potencial de desenvolvimento futuro."
        }
      `,
  },
};

const initialDistribution = {
  head: { schizoid: 0, oral: 0, psychopath: 0, masochist: 0, rigid: 0 },
  eyes: { schizoid: 0, oral: 0, psychopath: 0, masochist: 0, rigid: 0 },
  mouth: { schizoid: 0, oral: 0, psychopath: 0, masochist: 0, rigid: 0 },
  torso: { schizoid: 0, oral: 0, psychopath: 0, masochist: 0, rigid: 0 },
  hips: { schizoid: 0, oral: 0, psychopath: 0, masochist: 0, rigid: 0 },
  legs: { schizoid: 0, oral: 0, psychopath: 0, masochist: 0, rigid: 0 },
};

const initialProfile = {
  name: "",
  fieldOfWork: "",
  distribution: initialDistribution,
  insights: null,
};

const traitOrder = ["schizoid", "oral", "psychopath", "masochist", "rigid"];

const traitColors = {
  schizoid: "#FF6384",
  oral: "#36A2EB",
  psychopath: "#4BC0C0",
  masochist: "#FFCE56",
  rigid: "#9966FF",
};

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { margin: 10, padding: 10 },
  title: { fontSize: 18, fontWeight: "bold", marginBottom: 10 },
  subtitle: { fontSize: 14, fontWeight: "bold", marginBottom: 5 },
  text: { fontSize: 12, marginBottom: 5 },
});

const PDFReport = ({ profile }) => (
  <Document>
    <Page style={styles.page}>
      <Text style={styles.title}>{translations.title}</Text>
      <Text
        style={styles.subtitle}
      >{`${translations.name}: ${profile.name}`}</Text>
      <Text
        style={styles.subtitle}
      >{`${translations.fieldOfWork}: ${profile.fieldOfWork}`}</Text>
      {profile.insights && (
        <>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              {translations.insightsSections.mainQualities}
            </Text>
            {Object.entries(
              profile.insights.main_qualities_and_capabilities
            ).map(([key, content], index) => (
              <View key={index} style={styles.section}>
                <Text style={styles.subtitle}>
                  {translations.insightsSections[key]}
                </Text>
                <Text style={styles.text}>{content}</Text>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              {translations.insightsSections.emotionalChallenges}
            </Text>
            <Text style={styles.text}>
              {profile.insights.emotional_aspects_and_personal_challenges}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              {translations.insightsSections.conclusion}
            </Text>
            <Text style={styles.text}>{profile.insights.conclusion}</Text>
          </View>
        </>
      )}
    </Page>
  </Document>
);

function App() {
  const [profile, setProfile] = useState(() => {
    const savedProfile = localStorage.getItem("profile");
    return savedProfile ? JSON.parse(savedProfile) : initialProfile;
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("profile", JSON.stringify(profile));
  }, [profile]);

  const handleSliderChange = (part, trait, value) => {
    const totalPointsForPart = Object.values(profile.distribution[part]).reduce(
      (sum, val) => sum + val,
      0
    );
    const remainingPoints =
      MAX_POINTS_PER_PART -
      (totalPointsForPart - profile.distribution[part][trait]);

    if (value <= remainingPoints) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        distribution: {
          ...prevProfile.distribution,
          [part]: { ...prevProfile.distribution[part], [trait]: value },
        },
      }));
    }
  };

  const calculateRemainingPoints = (part) => {
    const totalUsed = Object.values(profile.distribution[part]).reduce(
      (sum, val) => sum + val,
      0
    );
    return MAX_POINTS_PER_PART - totalUsed;
  };

  const calculateTotals = () => {
    const totals = {
      schizoid: 0,
      oral: 0,
      psychopath: 0,
      masochist: 0,
      rigid: 0,
    };
    Object.values(profile.distribution).forEach((part) => {
      Object.keys(part).forEach((trait) => {
        totals[trait] += part[trait];
      });
    });
    return totals;
  };

  const calculatePercentages = () => {
    const totals = calculateTotals();
    const totalPoints = Object.values(totals).reduce(
      (sum, val) => sum + val,
      0
    );
    return Object.keys(totals).reduce((percentages, trait) => {
      percentages[trait] = ((totals[trait] / totalPoints) * 100).toFixed(2);
      return percentages;
    }, {});
  };

  const allPointsAllocated = () => {
    return Object.keys(profile.distribution).every(
      (part) => calculateRemainingPoints(part) === 0
    );
  };

  const fetchInsights = async () => {
    setLoading(true);
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const percentages = calculatePercentages();
    const distributionText = Object.keys(percentages)
      .map((trait) => `${translations[trait]}: ${percentages[trait]}%`)
      .join(", ");
    const prompt = translations.prompts.generateInsights(
      profile.name,
      profile.fieldOfWork,
      distributionText
    );

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: "gpt-4",
        messages: [{ role: "user", content: prompt }],
      }),
    });

    const data = await response.json();
    const insightsData = JSON.parse(data.choices[0].message.content);

    setProfile((prevProfile) => ({
      ...prevProfile,
      insights: insightsData,
    }));
    setLoading(false);
  };

  const exportData = () => {
    const dataStr = JSON.stringify(profile, null, 2);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = translations.fileNames.json;
    link.click();
    URL.revokeObjectURL(url);
  };

  const importData = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedProfile = JSON.parse(e.target.result);
          setProfile(importedProfile);
        } catch {
          alert(translations.importError);
        }
      };
      reader.readAsText(file);
    }
  };

  const resetData = () => {
    setProfile(initialProfile);
    localStorage.removeItem("profile");
  };

  const totals = calculateTotals();
  const totalPoints = Object.values(totals).reduce((sum, val) => sum + val, 0);

  const barData = {
    labels: traitOrder.map((trait) => translations[trait]),
    datasets: [
      {
        label: translations.totalPointsByTrait,
        data: traitOrder.map((trait) => totals[trait]),
        backgroundColor: traitOrder.map((trait) => traitColors[trait]),
        borderColor: traitOrder.map((trait) => traitColors[trait]),
        borderWidth: 1,
      },
    ],
  };

  const pieData = {
    labels: traitOrder.map((trait) => translations[trait]),
    datasets: [
      {
        label: translations.traitDistribution,
        data: traitOrder.map((trait) =>
          ((totals[trait] / totalPoints) * 100).toFixed(2)
        ),
        backgroundColor: traitOrder.map((trait) => traitColors[trait]),
      },
    ],
  };

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: { label: (context) => `${context.label}: ${context.raw}%` },
      },
      datalabels: {
        formatter: (value) => `${value}%`,
        color: "#fff",
        font: { weight: "bold", size: 14 },
      },
    },
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        {translations.title}
      </Typography>
      <Box my={2} display="flex" gap={2}>
        <TextField
          label={translations.name}
          value={profile.name}
          onChange={(e) => setProfile({ ...profile, name: e.target.value })}
          fullWidth
          required
        />
        <TextField
          label={translations.fieldOfWork}
          value={profile.fieldOfWork}
          onChange={(e) =>
            setProfile({ ...profile, fieldOfWork: e.target.value })
          }
          fullWidth
          required
        />
      </Box>
      <Box display="flex" justifyContent="center" gap={2} my={2}>
        <Button variant="contained" color="primary" onClick={exportData}>
          {translations.exportData}
        </Button>
        <Button variant="contained" component="label" color="secondary">
          {translations.importData}
          <input type="file" hidden accept=".json" onChange={importData} />
        </Button>
        <Button variant="contained" color="error" onClick={resetData}>
          {translations.reset}
        </Button>
        <Button
          variant="contained"
          color="success"
          startIcon={<SmartToy />}
          onClick={fetchInsights}
          disabled={!allPointsAllocated() || loading}
        >
          {loading ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size={20} sx={{ marginRight: 1 }} />
              {translations.loadingInsights}
            </Box>
          ) : (
            translations.insights
          )}
        </Button>
        <PDFDownloadLink
          document={<PDFReport profile={profile} />}
          fileName={translations.fileNames.pdf}
        >
          <Button variant="contained" color="secondary">
            {translations.exportPDF}
          </Button>
        </PDFDownloadLink>
      </Box>
      {profile.insights && (
        <Box my={4} p={2} bgcolor="#f0f0f0" borderRadius={1}>
          <Box my={2}>
            <Typography variant="h6" color="textPrimary">
              {translations.insightsSections.mainQualities}
            </Typography>
            {Object.entries(
              profile.insights.main_qualities_and_capabilities
            ).map(([key, content], index) => (
              <Box key={index} my={2}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {translations.insightsSections[key] ||
                    translations[key] ||
                    key.replace(/_/g, " ")}
                </Typography>
                <ReactMarkdown>{content}</ReactMarkdown>
              </Box>
            ))}
          </Box>
          <Box my={2}>
            <Typography variant="h6" color="textPrimary">
              {translations.insightsSections.emotionalChallenges}
            </Typography>
            <ReactMarkdown>
              {profile.insights.emotional_aspects_and_personal_challenges}
            </ReactMarkdown>
          </Box>
          <Box my={2}>
            <Typography variant="h6" color="textPrimary">
              {translations.insightsSections.conclusion}
            </Typography>
            <ReactMarkdown>{profile.insights.conclusion}</ReactMarkdown>
          </Box>
        </Box>
      )}
      <TableContainer component={Paper} sx={{ marginTop: 3, marginBottom: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: "25%" }}>
                {translations.bodyPart}
              </TableCell>
              {traitOrder.map((trait) => (
                <TableCell key={trait} align="center" sx={{ width: "15%" }}>
                  {translations[trait]}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ width: "10%" }}>
                {translations.remainingPoints}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(profile.distribution).map((part) => (
              <TableRow key={part}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  {translations[part]}
                </TableCell>
                {traitOrder.map((trait) => (
                  <TableCell key={trait} align="center">
                    <Slider
                      value={profile.distribution[part][trait]}
                      onChange={(e, value) =>
                        handleSliderChange(part, trait, value)
                      }
                      min={0}
                      max={MAX_POINTS_PER_PART}
                      step={1}
                      valueLabelDisplay="auto"
                      sx={{ color: traitColors[trait] }}
                    />
                  </TableCell>
                ))}
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    color:
                      calculateRemainingPoints(part) >= 0 ? "green" : "red",
                  }}
                >
                  {calculateRemainingPoints(part)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={5}>
        <Typography variant="h5" align="center" gutterBottom>
          {translations.traitDistribution}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center">
              {translations.totalPointsByTrait}
            </Typography>
            <Bar data={barData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center">
              {translations.traitDistribution}
            </Typography>
            <Pie data={pieData} options={pieOptions} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default App;
